import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Project({ post }) {
  const { title, featuredImage, slug } = post;
  console.log(featuredImage);
  const image = getImage(featuredImage?.node.localFile);
  return (
    <article className="pb-8 md:pb-2 w-full">
      <Link to={`/${slug}`}>
        <div className="pb-2">
          <GatsbyImage image={image} alt={title} />
        </div>
      </Link>

      <div>
        <Link to={`/${slug}`}>
          <h2 className="border-b  uppercase border-black inline-block">
            {title}
          </h2>
        </Link>
      </div>
    </article>
  );
}
