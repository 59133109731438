import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Project from "./Project";

export default function Projects() {
  const data = useStaticQuery(graphql`
    {
      allWpPage {
        nodes {
          title
          id
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: CONSTRAINED
                    quality: 75
                    maxWidth: 400
                    formats: JPG
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.allWpPage.nodes;
  return (
    <div className="md:grid grid-cols-3 gap-8">
      {posts.map((post) => {
        return <Project key={post.id} post={post} />;
      })}
    </div>
  );
}
