import React from "react";
import Projects from "../components/index/Projects";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout background="white" isHome>
      <SEO
        keywords={[`Henrik`, `Petersson`, `Photography`, `Växjö`]}
        title="Henrik Petersson Photography"
        isHome
      />

      <section className="text-center mx-aut pt-12 md:pt-10 pb-8">
        <Projects />
      </section>
    </Layout>
  );
}

export default IndexPage;
